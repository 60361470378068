import React from 'react';

interface SectionProps {
  children: JSX.Element;
  title: string;
  className?: string;
}

interface SkillsProps {
  name: string;
  score: number;
}

const skills: SkillsProps[] = [
  {
    name: 'html',
    score: 9.5
  },
  {
    name: 'css',
    score: 9.5
  },
  {
    name: 'javascript',
    score: 9
  },
  {
    name: 'typescript',
    score: 8
  },
  {
    name: 'solidity',
    score: 5
  },
  {
    name: 'graphQL',
    score: 9
  }
];

const frameworks: SkillsProps[] = [
  {
    name: 'react',
    score: 9
  },
  {
    name: 'ethers.js',
    score: 8
  },
  {
    name: 'hardhat',
    score: 7
  },
  {
    name: 'web3.js',
    score: 6
  },
  {
    name: 'angular',
    score: 6
  },
  {
    name: 'mud',
    score: 3
  }
];

const laguages: SkillsProps[] = [
  {
    name: 'ukrainian (fluent)',
    score: 9.5
  },
  {
    name: 'russian (fluent)',
    score: 9
  },
  {
    name: 'english (advanced)',
    score: 8
  },
  {
    name: 'german (elementary)',
    score: 3
  },
  {
    name: 'thai (beginner)',
    score: 1
  }
];

const Section: React.FC<SectionProps> = ({ children, title, className }) => (
  <div className={`bg-slate-700/20 rounded-md p-2 ${className}`}>
    <h5 className='text-lg font-bold'>{title}</h5>
    {children}
  </div>
);

const Bar = ({ title, score, color }: { title: string; score: number; color?: string }) => (
  <div>
    <div className='flex items-center justify-between'>
      <p className='text-sm'>{title}</p>
      <p className='text-xs'>
        <span className='font-bold'>{score}</span>/10
      </p>
    </div>
    <div className='w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700'>
      <div
        className={`${color ? color : 'bg-sky-600'} h-1.5 rounded-full`}
        style={{ width: `${score * 10}%` }}
      ></div>
    </div>
  </div>
);

function CVPage() {
  return (
    <div>
      <Section title='About' className='mb-2'>
        <p>
          Digital nomad. Blockchain researcher/engineer. Gamer. Keen eye for details. Creative
          thinking. Eager to create something new and exciting.
        </p>
      </Section>
      <Section title='Alma mater' className='mb-2'>
        <p>2009 - 2015: Uzhgorod National University. Software engineering, Bachelor degree.</p>
      </Section>
      <Section title='Skillz' className='mb-2'>
        <div>
          <div className='flex flex-wrap justify-between'>
            {skills.map((skill: SkillsProps) => (
              <div className='my-1' style={{ flexBasis: '49%' }}>
                <Bar title={skill.name} score={skill.score} />
              </div>
            ))}
          </div>
          <h5 className='text-lg font-bold'>Others</h5>
          <p>
            truffle, mocha, chai, nodejs, yaml, git, sass/less, netlify, google cloud, next.js,
            vercel
          </p>
        </div>
      </Section>
      <Section title='Framworks / Libraries' className='mb-2'>
        <div className='flex flex-wrap justify-between'>
          {frameworks.map((framworks: SkillsProps) => (
            <div className='my-1' style={{ flexBasis: '49%' }}>
              <Bar title={framworks.name} score={framworks.score} color='bg-purple-600' />
            </div>
          ))}
        </div>
      </Section>

      <Section title='Experience' className='mb-2'>
        <div>
          <div className='pl-6 relative mb-4'>
            <span className='absolute top-0 left-0'>💠</span>
            <h5 className='font-bold text-sm'>March 2021 - Present</h5>
            <h4 className='font-bold text-lg mb-1'>Co-founder, blockchain engineer at orden.gg</h4>
            <p className='mb-1'>
              The main purpose of creating orden was to learn blockchain technology and play fully
              onchain games. Since the beginning we were deeply involved in aavegotchi, darkforest,
              mithraeum community. Was working and shipping different tools such as alternative
              aavegotchi game client, various subgraphs, dropswap(darkforest), etc...
            </p>
            <ul className='list-disc pl-4'>
              <li>
                <a
                  className='font-bold underline hover:no-underline'
                  href='https://fireball.gg/'
                  rel='noreferrer'
                  target='_blank'
                >
                  fireball.gg
                </a>
                (
                <a
                  className='font-bold underline hover:no-underline'
                  href='https://github.com/orden-gg/fireball/'
                  rel='noreferrer'
                  target='_blank'
                >
                  github
                </a>
                )
              </li>
              <li>aavegotchi guilds (in progress)</li>
            </ul>
          </div>
          <div className='pl-6 relative mb-4'>
            <span className='absolute top-0 left-0'>⭐</span>
            <h5 className='font-bold text-sm'>November 2013 - March 2021</h5>
            <h4 className='font-bold text-lg mb-1'>Freelancer (elance, upwork) </h4>
            <p className='mb-1'>
              Worked mostly on web projects. Started as psd to html coder and involved to
              angular/react dev.
            </p>
            <ul className='list-disc pl-4'>
              <li>
                <a
                  className='font-bold underline hover:no-underline'
                  href='https://www.fiba.basketball/foundation/'
                  rel='noreferrer'
                  target='_blank'
                >
                  fiba.basketball/foundation
                </a>
              </li>
              <li>
                <a
                  className='font-bold underline hover:no-underline'
                  href='https://www.eurovisionsports.tv/'
                  rel='noreferrer'
                  target='_blank'
                >
                  eurovisionsports.tv
                </a>
              </li>
              <li>
                <a
                  className='font-bold underline hover:no-underline'
                  href='https://videoforblind.com/'
                  rel='noreferrer'
                  target='_blank'
                >
                  videoforblind.com
                </a>
              </li>
            </ul>
          </div>
          <div className='pl-6 relative'>
            <span className='absolute top-0 left-0'>⭐</span>
            <h5 className='font-bold text-sm'>October 2015 - January 2019</h5>
            <h4 className='font-bold text-lg mb-1'>Front-end dev at devrecords.com</h4>
            <p className='mb-1'>
              Full-time front-end developer. Worked on different long-term projects.
            </p>
            <ul className='list-disc pl-4'>
              <li>
                {' '}
                <a
                  className='font-bold underline hover:no-underline'
                  href='https://www.betterpoints.ltd/'
                  rel='noreferrer'
                  target='_blank'
                >
                  betterpoints.ltd
                </a>
              </li>
              <li>
                <a
                  className='font-bold underline hover:no-underline'
                  href='https://apps.apple.com/gb/app/reward-your-world/id730928392'
                  rel='noreferrer'
                  target='_blank'
                >
                  ios
                </a>{' '}
                <a
                  className='font-bold underline hover:no-underline'
                  href='https://play.google.com/store/apps/details?id=com.rewardyourworld.rewardyourworld'
                  rel='noreferrer'
                  target='_blank'
                >
                  android
                </a>{' '}
                betterpoints app
              </li>
              <li>
                education projects for{' '}
                <a
                  className='font-bold underline hover:no-underline'
                  href='https://everfi.com/'
                  rel='noreferrer'
                  target='_blank'
                >
                  everfi.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Section>

      <Section title='Languages' className='mb-2'>
        <div className='flex flex-wrap justify-between'>
          {laguages.map((language: SkillsProps) => (
            <div className='my-1' style={{ flexBasis: '49%' }}>
              <Bar title={language.name} score={language.score} color='bg-teal-500' />
            </div>
          ))}
        </div>
      </Section>

      <Section title='Hobbies'>
        <ul className=''>
          <li>🏂 Snowboarding</li>
          <li>🏄‍♂️ Surfing</li>
          <li>🎮 Gaming</li>
          <li>🥊 Boxing</li>
          <li>🎾 Squash</li>
          <li>📚 Learning languages</li>
        </ul>
      </Section>
    </div>
  );
}

export default CVPage;
