// import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import CVPage from './pages/cv.page';

function App() {
  return (
    <div className='min-h-screen bg-slate-400 p-4 flex grow'>
      <div className='container max-w-3xl mx-auto flex flex-col justify-center'>
        <Header />

        <CVPage />
      </div>
    </div>
  );
}

export default App;
