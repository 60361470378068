import discordIcon from '../images/icons/discord.svg';
import githubIcon from '../images/icons/github.svg';
import twitterIcon from '../images/icons/twitter.svg';

const socials = [
  {
    name: 'github',
    href: 'https://github.com/dudendy',
    icon: <img src={githubIcon} height={16} width={16} alt='github'></img>
  },
  {
    name: 'twitter',
    href: 'https://twitter.com/dudendy',
    icon: <img src={twitterIcon} height={16} width={16} alt='twitter'></img>
  },
  {
    name: 'discord',
    href: 'https://discord.com/users/dudendy',
    icon: <img src={discordIcon} height={16} width={16} alt='discord'></img>
  }
];

function Header() {
  return (
    <div className='mb-2'>
      <p className='text-center'>
        <span className='font-bold'>dudendy</span> aka{' '}
        <span className='font-bold'>Andrii Koval</span>
      </p>
      <div className='flex justify-center mt-1'>
        {socials.map((social: any) => (
          <a
            href={social.href}
            rel='noreferrer'
            target='_blank'
            className='flex items-center rounded-sm cursor-pointer bg-slate-400/25 py-0.5 px-1 mx-2 hover:bg-slate-600/20'
          >
            {social.icon}
            <span className='text-sm ml-1'>{social.name}</span>
          </a>
        ))}
      </div>

      <div className='text-center mt-2 mb-2 text-slate-800/10 hover:text-slate-800'>
        <p className='text-xs italic'>wit beyond measure is man's greatest treasure</p>
      </div>
    </div>
  );
}

export default Header;
